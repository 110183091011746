<template>
  <div class="main">
    <div id="user" class="app-container">
      <!-- <div class="leftList">
        <div class="user-center__R8SKINS-logo">
          <div
            class="picture-container user-center__R8SKINS-logo__picture"
            style="padding-top: 13.0169%; width: 100%"
          >
            <div
              class="picture"
              style="
                background-image: url('http://r8skins.com/img/logo.6a88e67b.png');
                background-size: contain;
              "
            ></div>
          </div>
        </div>
        <ul
          class="Menu ivu-menu ivu-menu-light ivu-menu-vertical"
          style="width: auto"
        >
          <li class="ivu-menu-item ivu-menu-item-active ivu-menu-item-selected">
            <a
              href="/#/userinfo"
              class="router-link-exact-active router-link-active"
              aria-current="page"
              ><i class="iconfont" style="font-size: 19px">&#xe65a;</i>个人中心
            </a>
          </li>
          <li class="ivu-menu-item ivu-menu-item-selected">
            <a
              href="/#/recaption"
              class="router-link-exact-active router-link-active"
              aria-current="page"
              ><i class="iconfont" style="font-size: 19px">&#xe61d;</i>我的库存
            </a>
          </li>
          <li class="ivu-menu-item ivu-menu-item-selected">
            <a
              href="/#/agency"
              class="router-link-exact-active router-link-active"
              aria-current="page"
              ><i class="iconfont" style="font-size: 19px">&#xe61a;</i>合作中心
            </a>
          </li>
          <li class="ivu-menu-item">
            <a href="/#/record" class=""
              ><i class="iconfont" style="font-size: 19px">&#xe61e;</i>
              充值记录
            </a>
          </li>
        </ul>
      </div> -->
      <leftList></leftList>

      <div class="rightView">
        <div id="userinfo">
          <div class="userinfo">
            <div class="userinfo__title">
              <div class="userinfo__title__text">个人中心</div>
              <div class="outLogin" @click="isout()">
                <img
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAABqElEQVQ4T42TPUiWYRSGr1sNBEUTxZ90EQTRUhxcmgwUHKTBIRAnBwVHQRArVyFpCHHTyaClyW8pgpac3FQsoSUS1EUQNEjF8Mh5OS88fH6BZ3re5+c659znfkUSZlYh6Z9vmVkZcA5UAb2S9tK7+Vr5wsyGgHXgAHjmjABU+rekb2b2GhgDFiQV/G0GMLNB4AtQDvwBWoHLBDAgadPMvgOPI+mopA2ZWRPgBw3AEfBc0raZPSgB6AE+AW2RqMcBq8AU4L33S9qNqu4AYr8T2AG8tQ8O8JKrgWVJM4kmJQEBWQReAVcOcLE8nkj6cU9AO/ArEzEA10CNJBcuCx9pCOnCPpW0lZz5iE+B2hzwN/sIDyQXJ4BmSW+K/OLTc8DDtIV2Sb9LmaV4z8xagOO8BS/fy12UtHBPwBywBNx4BR+BF8BFCJmJ878ws0fhmzqg4IAOYB/wsf0EhiW5ne9EPP4KdIXV+3IrTwJr8eIMeAu8Bw6lTCbPOg7MA/Vxb0bScvozTQPvwmF59pPI1JiU45rNSlrJRCwaT7eLCYxES+nxDfAZeJn+2rddncGFyD3o4gAAAABJRU5ErkJggg=="
                  alt="退出"
                />
                退出登录
              </div>
            </div>
            <div class="userinfo__header">
              <div class="userinfo__header__information">

                <!-- <div
                  class="avatar-container userinfo__header__information__avatar"
                  style="width: 55px"
                >
                  <div class="avatar avatar_has-border">
                    
                    <div
                      class="avatar__picture"
                      style="
                        background-image: url('http://api.r8skins.com/assets/a.jpg');
                      "
                    ></div>
                  </div>
                </div> -->
                <!-- 上传组件头像 -->
                <el-upload
                  class="avatar-uploader"
                  action="/user/logo/upload"
                  :http-request="upLoad"
                  :show-file-list="false"
                  :on-success="handleAvatarSuccess"
                  :before-upload="beforeAvatarUpload"

                  >
                  <!-- <img :src="imageUrl" class="avatar"> -->
                  <img :src=this.$store.state.userdata.logo alt=""  class="avatar"/>
                  <!-- <i v-else class="el-icon-plus avatar-uploader-icon"></i> -->
                </el-upload>

                <div>
                  <p class="userinfo__header__information__nickname">
                    {{this.$store.state.userdata.username}}
                    <el-button type="primary" icon="el-icon-edit-outline" circle @click="open"></el-button>
                  </p>
                </div>
              </div>
              <div class="userinfo__header__setUp">
                <p class="userinfo__header__setUp__tips">
                  请确认你的报价链接有效，并且设置Steam库存公开。
                  <a
                    href="https://steamcommunity.com/my/tradeoffers/privacy#trade_offer_access_url"
                    target="_blank"
                    >获取交易链接</a
                  >
                </p>
                <div class="userinfo__header__setUp__input">
                  <p class="userinfo__header__setUp__input__title">交易链接</p>
                  <form auto-complete="off" action="" style="-webkit-user-select:text !important;">
                    <input type="text" style="-webkit-user-select:text !important;" v-model="offertet" :placeholder=offertetdata  />
                  </form>
                  <div style="display: flex;justify-content: space-evenly;">
                    <div class="btn" @click="offerbtu">保存</div>
                    <div class="btn btns" style="display: none;" @click="handleWj">修改密码</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="userinfo__main">
              <div class="userinfo__main__bind">
                <div class="userinfo__main__bind__left">
                  <div class="userinfo__main__bind__title">手机号</div>
                  <div>{{this.$store.state.userdata.phone}}</div>
                </div>
                <div class="btn btnnone" @click="handleWj">修改密码</div>
              </div>
              <div class="userinfo__main__bind">
                <div class="userinfo__main__bind__left">
                  <div class="userinfo__main__bind__title">推荐人</div>
                  <div>{{this.$store.state.userdata.sub}}</div>
                </div>
              </div>
              <div class="userinfo__main__bind">
                <div class="userinfo__main__bind__left">
                  <div class="userinfo__main__bind__title">绑定推荐码</div>
                  <div>{{this.$store.state.userdata.subCode}}</div>
                </div>
              </div>
              <div class="userinfo__main__bind">
                <div class="userinfo__main__bind__left">
                  <div class="userinfo__main__bind__title">邀请码</div>
                  <div>{{this.$store.state.userdata.code}}</div>
                </div>
              </div>
              <div class="userinfo__main__bind">
                <div class="userinfo__main__bind__left">
                  <div class="userinfo__main__bind__title">
                    <div>余额(Coins)</div>
                  </div>
                  <div>{{this.$store.state.userdata.coin}}</div>
                </div>
              </div>
              <div class="userinfo__main__bind">
                <div class="userinfo__main__bind__left">
                  <div class="userinfo__main__bind__title">
                    <div>积分</div>
                  </div>
                  <div>{{this.$store.state.userdata.integral}}</div>
                </div>
              </div>
            </div>
          </div>
          <!---->
        </div>
      </div>
    </div>

    <!-- 红包 -->
    <Redenvelopes></Redenvelopes>
  </div>
</template>

<script>
import Redenvelopes from "../components/Redenvelopes";
import { Button  } from 'element-ui';
import leftList from "../components/userleftList"
export default {
  components: {
    Redenvelopes,
    "el-button":Button,
    leftList,
    
  },
  data() {
    return {
      imageUrl: this.$store.state.userdata.logo,
      offertet:'',//报价链接
      //offertetdata:'保存后不可更改，如须更改请联系客服',//报价提示
      offertetdata:'绑定后不可更改，请认真核对无误再保存',//报价提示

    };
  },
  methods: {
    // 红包
    redPrize() {
      document.getElementsByClassName("ivu-modal")[0].style.display = "block";
      document.getElementById("isquan").classList.add("backghui");
    },
    //退出登录
    isout() {
      this.$router.push('/')
      localStorage.removeItem("token");
      this.$store.commit("token", "");
      this.$store.commit("userdata", "");
      location.reload();
    },
    //修改用户名
    open() {
      this.$prompt('请输入修改用户名', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /^[\u0391-\uFFE5A-Za-z0-9]{3,8}$/,
        inputErrorMessage: '昵称格式不正确(3-8位字母或者汉字)'
      }).then(({ value }) => {
        this.$axios({
          url:'/user/set/name',
          method:'post',
          data:{
            username:value,
            // token:localStorage.getItem("token")
          }
        }).then( res => {
          if(res.status == 200){
            this.$store.commit("username_up", value);
            this.$message({
              type: 'success',
              showClose: true,
              message: res.data.msg + '你的用户名是: ' + value
            });
          }else{
            this.$message({
              type: 'error',
              showClose: true,
              message: '修改失败: ' + res.data.msg
            });
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          showClose: true,
          message: '取消'
        });       
      });
    },
    //修改密码
    handleWj() {
      document.getElementsByClassName("form-wnd")[0].classList.remove("active");
      document.getElementsByClassName("form-wnd")[1].classList.add("active");
      
    },
    //修改密码
    handelReset() {
      console.log(this.phone, this.password, this.verification);
    },
    //报价链接
    offerbtu(){
      if(this.offertet){
        this.$confirm('一旦设置成功不能更改, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
        this.$axios({
          url:'user/set/url',
          method:'post',
          data:{
            url:this.offertet,
          }
        }).then( res => {
          if(res.data.status == 200){
            this.$message({
              type: 'success',
              showClose: true,
              message: res.data.msg
            });
          }else{

            this.$message({
              type: 'error',
              showClose: true,
              message: res.data.msg
            });
          }
        })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '取消'
          });          
        });
      }else if(this.$store.state.userdata.url){
        this.$message({
          type: 'error',
          showClose: true,
          message: '您的交易链接已存在'
        }); 
      }else{
        this.$message({
          type: 'error',
          showClose: true,
          message: '请输入您的交易链接'
        });  
      }
    },
    //头像
    upLoad(file) {
      const formData = new FormData();
      formData.append('file', file.file);
      formData.append('token', localStorage.getItem("token"));

      // this.$axios({
      //   url:'/user/logo/upload',
      //   method:'post',
      //   formData,
      // }).then( res => {
      //   // console.log(res);
      // })

      this.$axios.post("/user/logo/upload",formData).then( res => {
          const data = res.data
          if(data.status === 200){
            this.$store.commit("userimg_up", data.data.src);
            this.$message({
              type: 'success',
              showClose: true,
              message: data.msg
            });
          }else{
            this.$message({
              showClose: true,
              message: data.msg,
              type: 'error'
            });
          }
      });

      // this.$http.uploadHttp('/admin/api/upload', file.file).then((res) => {
      //   console.log(res);
      //   console.log('上传成功');
      //   if (res.data.code === 0) {
      //     this.successUrl = res.data.response; // 请求之后将返回的URL给修改的接口在进行一次修改请求
      //     this.headUrl = res.data.response; // 请求成功之后赋给头像的URL
      //     this.$message('头像上传成功');
      //     this.$store.dispatch('person/setAvatar', this.successUrl);
      //     const obj = {
      //       headUrl: this.successUrl
      //     };
      //     this.edit(obj);
      //     // this.getAccount();
      //   } else {
      //     this.$message('头像上传失败');
      //   }
      // });
    },
    //上传完成的钩子函数
    handleAvatarSuccess(res, file) {
        this.imageUrl = URL.createObjectURL(file.raw);
    },
    //上传前的钩子函数
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg' || 'image/png';
      const isLt2M = file.size / 1024 / 1024< .2;
      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 或 PNG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 200K!');
      }
      return isJPG && isLt2M;
    },
    //登录
    handleZhu() {
      document.getElementsByClassName("form-wnd")[0].classList.add("active");
      document.getElementsByClassName("popup-bg")[0].classList.add("active");
      document.getElementsByClassName("switch-block")[0].classList.add("active");
      document.getElementsByClassName("switch-block")[1].classList.remove("active");
      document.getElementsByClassName("button")[0].classList.add("active");
      document.getElementsByClassName("button")[1].classList.remove("active");
      this.isBar = false;
      this.password=''
    },
  },
  mounted() {
    if(!localStorage.getItem("token")){
      this.$message({
        showClose: true,
        message: '请先登录'
      });
      this.$router.push('/')
      //提示登录框
      setTimeout( ()=> {
        this.handleZhu()
      },300)
    }
    setTimeout( () =>{
      if((this.$store.state.userdata.url != undefined && this.$store.state.userdata.url != '')){
        this.offertetdata = this.$store.state.userdata.url
      }
    },500)
  },
};
</script>

<style scoped lang='less'>
@import url("../assets/csscopy/user/share.css");
@import url("../assets/csscopy/user/userinfon.css");
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 80px;
  height: 80px;
  line-height: 100px;
  text-align: center;
}
.avatar{
  padding-top: 0px;
  width: 80px;
  height: 80px;
  margin-right: 20px;
}
.picture{
  background-image: url("../assets/img/logo.6a88e67b.png") !important;
}
@media screen and (max-width: 800px) {
  .leftList{
    width: 20% !important;
  }
  .rightView{
    display:block !important;
    width: 80%;
  }
  .userinfo__header{
    display: block !important;
  }
  .userinfo__header__setUp__input{
    flex-direction: column !important;
    width: 100% !important;
  }
  .userinfo__header__setUp__input__title{
    text-align: initial !important;
  }
  .btn{
    margin-left: 0 !important;
    margin-top: 5px !important;
  }
  .btnnone{
    display: none;
  }
}
</style>
<style>
  @media screen and (max-width: 800px) {
    .el-message-box{
      width: 95% ;
    }
    .btns{
      display: block !important;
    }
    .userinfo__header__setUp__tips{
      text-align: center !important;
    }
  }
</style>